import {
  Box,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, { useState } from "react";
import cx from "classnames";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Add from "@material-ui/icons/Add";
import Close from "@material-ui/icons/Close";

import OptionsGroup from "../../components/OptionsGroup";
import {
  assetsOptions,
  moneyTypeOptions,
  motorVehicleOptions,
  yesNoOptions,
} from "../../../../commons/loan-application-constants";
import BaseMoneyInput from "../../components/Inputs/BaseMoneyInput";

import {
  useCommonStyles,
  useStyles as useStepStyles,
} from "../../../../utils/loan-application.styles";
import SingleInput from "../../components/Inputs/SingleInput";
import Button from "../../components/Button";
import { setFieldTouchedRecusively } from "../../../../utils/loan-application.utils";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    gridRowGap: "48px",
    paddingBottom: "24px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  owningInputContainer: {
    display: "flex",
    justifyContent: "space-between",
    gap: "24px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  summaryContainer: {
    width: "100%",
    display: "grid",
    gridGap: "24px 24px",
    gridTemplateColumns: "1fr 1fr",
    gridAutoRows: "1fr 1fr",
    marginBottom: "24px",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    },
  },
  summaryCard: {
    padding: "24px",
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  summaryHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  summaryValue: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  editButton: {
    padding: "5px 15px",
    [theme.breakpoints.down("xs")]: {
      padding: "2px 8px",
    },
  },
  actionButtonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    gap: "16px",
  },
}));

const MotorVehicle = ({
  formik,
  index: assetTypeIndex,
  handleNextStep,
  handlePrevStep,
  handleSaveSection,
  isPropertyFinished,
  setIsPropertyFinished,
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const stepClasses = useStepStyles();
  const isMobile = useMediaQuery("(max-width:599px)");
  const [isFinished, setIsFinished] = useState(false);
  const [currentItemIndex, setCurrentItemIndex] = useState(0);

  // Set initial state based on isEditing or isPropertyFinished
  React.useEffect(() => {
    if (isPropertyFinished) {
      setIsFinished(true);
    }
  }, []);

  // Cleanup invalid items when showing list view
  React.useEffect(() => {
    if (isFinished) {
      const validItems = formik.values.assets[assetTypeIndex].items.filter(
        (_, itemIndex) => {
          const itemErrors =
            formik.errors?.assets?.[assetTypeIndex]?.items?.[itemIndex];
          return !itemErrors;
        }
      );

      formik.setFieldValue(`assets[${assetTypeIndex}].items`, validItems);
      setIsPropertyFinished(true);
    }
  }, [isFinished]);

  const currentItemsData = formik.values.assets[assetTypeIndex].items;
  const currentItemValuesObject = formik.values.assets
    ?.at(assetTypeIndex)
    ?.items?.at(currentItemIndex);
  const currentItemTouchedObject = formik.touched.assets
    ?.at(assetTypeIndex)
    ?.items?.at(currentItemIndex);
  const currentItemErrorsObject = formik.errors.assets
    ?.at(assetTypeIndex)
    ?.items?.at(currentItemIndex);
  const currentItemFieldPath = `assets[${assetTypeIndex}].items[${currentItemIndex}]`;

  const validateFields = async () => {
    const data = await formik.validateForm();
    if (!data?.assets?.[assetTypeIndex]?.items?.[currentItemIndex]) {
      return true;
    }
    formik.setFieldTouched(`${currentItemFieldPath}.category`);
    formik.setFieldTouched(`${currentItemFieldPath}.estimateValue`);
    formik.setFieldTouched(`${currentItemFieldPath}.ownership`);
    if (currentItemValuesObject?.hasLoan) {
      formik.setFieldTouched(`${currentItemFieldPath}.currentOwing`);
      formik.setFieldTouched(`${currentItemFieldPath}.originalLoanAmount`);
      formik.setFieldTouched(`${currentItemFieldPath}.loanRepaymentAmount`);
    } else {
      formik.setFieldTouched(`${currentItemFieldPath}.currentOwing`, false);
      formik.setFieldTouched(
        `${currentItemFieldPath}.originalLoanAmount`,
        false
      );
      formik.setFieldTouched(
        `${currentItemFieldPath}.loanRepaymentAmount`,
        false
      );
    }
    return false;
  };

  const onNextStep = async () => {
    if (await validateFields()) {
      setIsFinished(true);
      handleNextStep();
    }
  };

  const onSaveSection = async () => {
    if (await validateFields()) {
      setIsFinished(true);
      handleSaveSection();
    }
  };

  const onAddItem = async () => {
    const newIndex = formik.values.assets[assetTypeIndex].items.length;
    const ownership = formik.values.assets[0].items[0]?.ownership || [];
    const newItem = formik.initialValues.assets[0].items[0];
    newItem.ownership = ownership;
    newItem.liabilityOwners = undefined;
    await formik.setFieldValue(
      `assets[${assetTypeIndex}].items[${newIndex}]`,
      newItem
    );
    await setFieldTouchedRecusively(
      formik,
      `assets[${assetTypeIndex}].items[${newIndex}]`,
      false,
      false
    );
    setIsFinished(false);
    setCurrentItemIndex(newIndex);
  };

  const onDeleteItem = () => {
    formik.setFieldValue(
      `assets[${assetTypeIndex}].items`,
      [...formik.values.assets[assetTypeIndex].items].filter(
        (_, i) => i !== currentItemIndex
      )
    );
    setIsFinished(true);
    setCurrentItemIndex(0);
  };

  const handleEditItem = (itemIndex) => {
    setCurrentItemIndex(itemIndex);
    setIsFinished(false);
  };

  const handleSaveItem = async () => {
    if (await validateFields()) {
      setIsFinished(true);
    }
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value || 0);
  };

  if (isFinished) {
    return (
      <Box>
        <Box classes={{ root: classes.summaryContainer }}>
          {currentItemsData.map((item, itemIndex) => (
            <Box
              key={`vehicle-${assetTypeIndex}-${item.id || itemIndex}`}
              classes={{ root: classes.summaryCard }}
            >
              <Box classes={{ root: classes.summaryHeader }}>
                <Typography variant="h6">
                  Motor Vehicle {itemIndex + 1}
                </Typography>
                <Button
                  onClick={() => handleEditItem(itemIndex)}
                  classes={classes.editButton}
                >
                  Edit
                </Button>
              </Box>
              <Box classes={{ root: classes.summaryValue }}>
                <Typography>Value</Typography>
                <Typography>{formatCurrency(item.estimateValue)}</Typography>
              </Box>
              {item.hasLoan && (
                <Box classes={{ root: classes.summaryValue }}>
                  <Typography>Loan Amount</Typography>
                  <Typography>{formatCurrency(item.currentOwing)}</Typography>
                </Box>
              )}
            </Box>
          ))}
        </Box>
        <Grid container xs={12} md={6} justifyContent="space-between">
          <Button endIcon={<Add />} onClick={onAddItem}>
            Add Additional{" "}
            {
              assetsOptions.find(
                (option) =>
                  option.value ===
                  formik.values.assets[assetTypeIndex].assetType
              ).shortTerm
            }
          </Button>
        </Grid>
        <Grid
          container
          xs={12}
          md={6}
          justifyContent="space-between"
          classes={{ root: stepClasses.navigationButtonsContainer }}
        >
          {assetTypeIndex !== 0 && (
            <Button startIcon={<NavigateBeforeIcon />} onClick={handlePrevStep}>
              Previous
            </Button>
          )}
          {assetTypeIndex !== formik.values.assets.length - 1 ? (
            <Button endIcon={<NavigateNextIcon />} onClick={onNextStep}>
              Next
            </Button>
          ) : (
            <Button endIcon={<NavigateNextIcon />} onClick={onSaveSection}>
              Save and Next
            </Button>
          )}
        </Grid>
      </Box>
    );
  }

  return (
    <Box classes={{ root: classes.container }}>
      <Box>
        <OptionsGroup
          options={motorVehicleOptions}
          onSelect={(value) =>
            formik.setFieldValue(`${currentItemFieldPath}.category`, value)
          }
          selectedValue={currentItemValuesObject?.category}
          error={
            currentItemTouchedObject?.category &&
            Boolean(currentItemErrorsObject?.category)
          }
          helperText={
            currentItemTouchedObject?.category &&
            currentItemErrorsObject?.category
          }
        />
      </Box>
      <Box display="flex" flexDirection="column" gridRowGap="24px">
        <BaseMoneyInput
          options={yesNoOptions}
          label="Vehicle estimate"
          optionLabel="Has a loan"
          selectedOption={currentItemValuesObject?.hasLoan}
          setSelectedOption={(value) =>
            formik.setFieldValue(`${currentItemFieldPath}.hasLoan`, value)
          }
          value={currentItemValuesObject?.estimateValue}
          onChange={(value) =>
            formik.setFieldValue(`${currentItemFieldPath}.estimateValue`, value)
          }
          error={
            currentItemTouchedObject?.estimateValue &&
            Boolean(currentItemErrorsObject?.estimateValue)
          }
          helperText={
            currentItemTouchedObject?.estimateValue &&
            currentItemErrorsObject?.estimateValue
          }
        />
      </Box>
      {currentItemValuesObject?.hasLoan && (
        <Box display="flex" flexDirection="column" gridRowGap="48px">
          <Box classes={{ root: classes.owningInputContainer }}>
            <BaseMoneyInput
              label="How much is owing"
              value={currentItemValuesObject?.currentOwing}
              onChange={(value) =>
                formik.setFieldValue(
                  `${currentItemFieldPath}.currentOwing`,
                  value
                )
              }
              error={
                currentItemTouchedObject?.currentOwing &&
                Boolean(currentItemErrorsObject?.currentOwing)
              }
              helperText={
                currentItemTouchedObject?.currentOwing &&
                currentItemErrorsObject?.currentOwing
              }
            />
            <BaseMoneyInput
              label="Original loan amount"
              value={currentItemValuesObject?.originalLoanAmount}
              onChange={(value) =>
                formik.setFieldValue(
                  `${currentItemFieldPath}.originalLoanAmount`,
                  value
                )
              }
              error={
                currentItemTouchedObject?.originalLoanAmount &&
                Boolean(currentItemErrorsObject?.originalLoanAmount)
              }
              helperText={
                currentItemTouchedObject?.originalLoanAmount &&
                currentItemErrorsObject?.originalLoanAmount
              }
            />
          </Box>
          <BaseMoneyInput
            options={moneyTypeOptions}
            label="How much are your loan repayments"
            maxValue={12500}
            optionLabel="Repayment Frequency"
            selectedOption={currentItemValuesObject?.repaymentFrequency}
            setSelectedOption={(value) =>
              formik.setFieldValue(
                `${currentItemFieldPath}.repaymentFrequency`,
                value
              )
            }
            value={currentItemValuesObject?.loanRepaymentAmount}
            onChange={(value) =>
              formik.setFieldValue(
                `${currentItemFieldPath}.loanRepaymentAmount`,
                value
              )
            }
            error={
              currentItemTouchedObject?.loanRepaymentAmount &&
              Boolean(currentItemErrorsObject?.loanRepaymentAmount)
            }
            helperText={
              currentItemTouchedObject?.loanRepaymentAmount &&
              currentItemErrorsObject?.loanRepaymentAmount
            }
          />
        </Box>
      )}
      <Box display="flex" flexDirection="column" gridRowGap="16px">
        <Typography
          classes={{
            root: cx(commonClasses.fontSize14, commonClasses.fontWeightBold),
          }}
        >
          Ownership
        </Typography>
        {currentItemValuesObject?.ownership?.length ? (
          <Box display="flex" flexWrap="wrap" gridColumnGap="48px">
            {currentItemValuesObject?.ownership.map((owner, ownerIndex) => (
              <SingleInput
                // eslint-disable-next-line react/no-array-index-key
                key={ownerIndex}
                type="number"
                label={owner.name}
                endAdornment="%"
                value={owner.percentage}
                onChange={(value) =>
                  formik.setFieldValue(
                    `${currentItemFieldPath}.ownership[${ownerIndex}].percentage`,
                    value
                  )
                }
              />
            ))}
            {currentItemErrorsObject?.ownership &&
              currentItemTouchedObject?.ownership && (
                <Typography color="error">
                  {currentItemErrorsObject?.ownership}
                </Typography>
              )}
          </Box>
        ) : (
          <Box>
            <Typography color="error">
              Please enter applicant(s) information first
            </Typography>
          </Box>
        )}
      </Box>
      <Box classes={{ root: classes.actionButtonsContainer }}>
        <Button endIcon={<Add />} onClick={handleSaveItem}>
          Save {`${isMobile ? "" : " This Vehicle"}`}
        </Button>
        {formik.values.assets[assetTypeIndex].items.length > 1 && (
          <Button
            endIcon={<Close />}
            onClick={onDeleteItem}
            customColor="danger"
          >
            Delete {`${isMobile ? "" : " This Vehicle"}`}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default MotorVehicle;
